import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Slider from "react-slick";
import SliderSettings from "../components/SliderSettings";
import Banner from "../components/Banner";
import IconText from "../components/IconText";
import {Grid, Button} from '@material-ui/core';
import Separator from "../components/Separator";
import Image from "../components/Image";
import Layout from "../components/Layout";
import Map from "../components/Map";
import TitleDescriptionBlock from "../components/TitleDescriptionBlock";
import IconTexts from "../components/IconTexts";
import Gallery from "../components/Gallery";
import {Contact} from "../components/Contact";
import theme from "../theme";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import SEO from "../components/SEO";
import Pillars from "../components/Pillars";

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            markers: [
                {
                    title: this.props.data.wordpressPage.acf.maps.default.title,
                    key: this.props.data.wordpressPage.acf.maps.default.title,
                    description: this.props.data.wordpressPage.acf.maps.default.description,
                    link: this.props.data.wordpressPage.acf.maps.default.link,
                    lat: parseFloat(this.props.data.wordpressPage.acf.maps.lat),
                    lng: parseFloat(this.props.data.wordpressPage.acf.maps.lng)
                }
            ]
        };
    }

    render() {
        const currentPage = this.props.data.wordpressPage;
        return (
            <Layout>
                <SEO title={currentPage.yoast_meta.yoast_wpseo_title}
                     description={currentPage.yoast_meta.yoast_wpseo_metadesc}
                />
                <Slider {...SliderSettings}>
                    {currentPage.acf.slider.map((slide, index) => {
                        return (
                            <Banner
                                key={slide.default.title + "_" + index}
                                title={slide.default.title}
                                description={slide.default.description}
                                image={slide.default.image}
                                link={slide.default.link}
                                linktext={slide.default.link_text}
                            />
                        );
                    })}
                </Slider>

                {/*<IconTexts items={currentPage.acf.icontexts} color='white' />*/}

                <TitleDescriptionBlock title={currentPage.acf.intro.title} description={currentPage.acf.intro.description} style={{marginBottom: '2em'}}/>

                <Grid container justify='center'>
                    <Grid item>
                        <Link to={currentPage.acf.trainingsconzept_button.link} style={{textDecoration: 'none'}}>
                            <Button color="secondary" style={{color:'white', fontSize: '1em'}} size="large" variant="contained">
                                <ArrowRightIcon /> {currentPage.acf.trainingsconzept_button.link_text}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

                <Pillars items={currentPage.acf.pillars.icontexts} />

                <div id="contact">
                    <Contact contact={currentPage.acf.contact} />
                </div>

                <TitleDescriptionBlock intro={currentPage.acf.contact.membership.title} description={currentPage.acf.contact.membership.description} />

                <Map containerStyles={{height: '80vh', marginTop: '2em'}}
                     zoom={parseInt(currentPage.acf.maps.zoom, 10)}
                     markers={this.state.markers}
                />

                <div id='about'>
                    <Separator dark title="Über Herzschlag" />
                </div>

                <TitleDescriptionBlock intro={currentPage.acf.about.title} description={currentPage.acf.about.description} />

                <Grid container spacing={10} justify="center" className="grid-default" style={{marginTop: '3em'}}>


                    {currentPage.acf.persons.map((item, index) => {
                        return (
                            <Grid key={item.person.title + "_" + index} item lg={4} md={4} sm={12} xs={12}>
                                <IconText
                                    title={item.person.title}
                                    description={item.person.description}
                                    icon={item.person.image}
                                    rounded={true}
                                />
                            </Grid>
                        );
                    })}
                </Grid>

                <div id='gallery'>
                    <Separator dark title="Galerie" />
                </div>

                <h3 style={{textAlign: 'center'}}>Milon</h3>
                <Gallery images={currentPage.acf.gallery_milon} style={{padding: '1em'}} />

                <h3 style={{textAlign: 'center'}}>Five</h3>
                <Gallery images={currentPage.acf.gallery_five} style={{padding: '1em'}} />
            </Layout>
        )
    }
}

export default Index

export const pageQuery = graphql`
    query {
        wordpressPage(template: { eq: "template-landing.php" }) {
            title
            date(formatString: "MMMM DD, YYYY")
            ...seo
            acf {
                ...sliderFragment
                ...introFragment
                ...pillars
                ...contact
                maps {
                    zoom
                    lat
                    lng
                    default {
                        description
                        link
                        link_text
                        title
                    }
                }
                gallery_milon {
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                            }
                        }
                    }
                }
                gallery_five {
                    alt_text
                    title
                    description
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                            }
                        }
                    }
                }
                persons {
                    person {
                        title
                        image {
                            alt_text
                            title
                            description
                            localFile {
                                childImageSharp {
                                    id
                                    fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                    }
                                }
                            }
                        }
                    }
                }
                about {
                    title
                    description
                }
                trainingsconzept_button {
                    link_text
                    link
                }
            }
        }
    }
`