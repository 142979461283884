import React, { useState } from 'react';
import {Link} from 'gatsby'
import {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} from "react-google-maps"
import {Button} from "@material-ui/core";
const { compose, withProps } = require("recompose");

function InfoWindowMarker({marker}) {
    const [open, setOpen] = useState(true);

    const handleToggle = function() {
        setOpen(!open);
    };

    return (
        <Marker onClick={handleToggle} position={{lat: marker.lat, lng: marker.lng}}>
            {
                open && <InfoWindow onCloseClick={handleToggle}>
                    <div>
                        <h1>{marker.title}</h1>
                        <p dangerouslySetInnerHTML={{__html: marker.description}}></p>
                        {marker.link && <Link to={marker.link}>
                            <Button variant="contained" color="primary" size='small'>
                                Erfahre mehr...
                            </Button>
                        </Link>}
                    </div>
                </InfoWindow>
            }
        </Marker>
    )
}

export default function Map({markers, containerStyles, zoom}) {
    const MyMapComponent = compose(
        withProps({
            googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyC5P2lf66CTVQAQwHiQqHoOnkMEOyk0tk8&libraries=geometry,drawing,places",
            loadingElement: <div style={{ height: `100%` }} />,
            containerElement: <div style={containerStyles} />,
            mapElement: <div style={{ height: `100%` }} />,
        }),
        withScriptjs,
        withGoogleMap
    )((props) =>
        <GoogleMap
            defaultZoom={zoom}
            defaultCenter={{ lat: markers && markers[0].lat,lng:markers && markers[0].lng }}
        >
            {
                markers && markers.map((marker) => {
                    return <InfoWindowMarker key={marker.key} marker={marker} />
                })
            }

        </GoogleMap>
    );

    return (
        <MyMapComponent />
    );
}

